
import apiKeys from "./apiKeys";
import Clock from "react-live-clock";
import Forcast from "./forcast";
import loader from "./images/WeatherIcons.gif";
import ReactAnimatedWeather from "react-animated-weather";
import React from 'react'; 
import nodataImg from "./images/nodata.png";



const dateBuilder = (d) => {
    let months = [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
    ];
    let days = [
        "Sonntag",
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag",
    ];

    let day = days[d.getDay()];
    let date = d.getDate();
    let month = months[d.getMonth()];
    let year = d.getFullYear();

    return `${day}, ${date} ${month} ${year}`;
};
const defaults = {
    color: "white",
    size: 112,
    animate: true,
};

const datastate = {
    labels: ['January', 'February', 'March',
        'April', 'May'],
    datasets: [
        {
            label: 'Rainfall',
            fill: false,
            lineTension: 0.5,
            backgroundColor: 'rgba(75,192,192,1)',
            borderColor: 'rgba(0,0,0,1)',
            borderWidth: 2,
            data: [65, 59, 80, 81, 56]
        }
    ]
}

//type locations = { code: string, description: string };
let locations = [
    { code: "DEBERL2", description: "Berlin (Tempelhof)" },
    { code: "DEALTO", description: "Altötting" },
    { code: "DEMUNC", description: "München Thalkirchner Straße" },
    { code: "DEBIED", description: "München (Biedersteiner Straße)" },
    { code: "DEFEUC", description: "Feucht" },
    { code: "DEGARM", description: "Garmisch-Partenkirchen" },
    { code: "DEUFS2", description: "Schneefernerhaus" },
    { code: "DEHOF", description: "Hof" },
    { code: "DEMARK", description: "Marktheidenfeld" },
    { code: "DEMIND", description: "Mindelheim" },
    { code: "DEVIEC", description: "Viechtach" },
    { code: "DELEIP", description: "Leipzig" },
    { code: "DEWIES", description: "Wiesbaden" },
    { code: "DEWIES", description: "Wiesbaden" }
]


// Function to translate a single word using the dictionary
function translateWord(word, translationDict) {
    // Translate the word using the dictionary
    return translationDict[word] || word;
}

function checkIfPollenExists(textToCheck) {
    const noPollenList = ["Schimmel", "Alternaria"];
    return noPollenList.includes(textToCheck);
}

class Pollen extends React.Component {
    datatos = [];
    state = {
        lat: undefined,
        lon: undefined,
        errorMessage: undefined,
        temperatureC: undefined,
        temperatureF: undefined,
        city: undefined,
        country: undefined,
        humidity: undefined,
        description: undefined,
        icon: "CLEAR_DAY",
        sunrise: undefined,
        sunset: undefined,
        errorMsg: undefined,
        pollenList: [],
        noDataList: [],
        germanTranslationDict : {
            "Abies": "Tanne",
            "Acer": "Ahorn",
            "Aesculus": "Rosskastanie",
            "Alnus": "Erle",
            "Ambrosia": "Ambrosia",
            "Artemisia": "Beifuss",
            "Betula": "Birke",
            "Carpinus": "Hainbuche",
            "Castanea": "Kastanie",
            "Chenopodium": "Gänsefuß",
            "Corylus": "Hasel",
            "Cyperaceae": "Sauergräser",
            "Fagus": "Buche",
            "Fraxinus": "Esche",
            "Humulus": "Hopfen",
            "Juglans": "Walnuss",
            "Picea": "Fichte",
            "Pinus": "Kiefer",
            "Plantago": "Wegerich",
            "Poaceae": "Gräser",
            "Populus": "Pappel",
            "Quercus": "Eiche",
            "Urtica": "Brennnessel",
            "Salix": "Weide",
            "Taxus": "Zypresse/Eibe",
            "Tilia": "Linde",
            "Ulmus": "Ulme",
            "Fungus": "Schimmel",
            "Alternaria": "Alternaria",
            "Rumex": "Ampfer",
            "Platanus": "Platane",
            "Impatiens": "Springkraut",
            "Pinaceae": "Kieferngewächse",
            "Asteraceae": "Korbblütler",
            "Cruciferae": "Kreuzblütler",
            "Erica": "Heidekraut",
            "Galium": "Labkräuter",
            "Larix": "Lärche",
            "Quercus ilex": "Steineiche",
            "Sambucus": "Holunder",
            "Roggen": "Secale"
        }
    };

  
    componentDidMount() {
       
        //if (navigator.geolocation) {
        //    this.getPosition()
        //        //If user allow location service then will fetch data & send it to get-weather function.
        //        .then((position) => {
        //            // this.getWeather(position.coords.latitude, position.coords.longitude);
        //            // this.getWeather(position.coords.latitude, position.coords.longitude);
        //            this.getWeather(48.1374, 11.5755);
        //        })
        //        .catch((err) => {
        //            //If user denied location service then standard location weather will le shown on basis of latitude & latitude.
        //            this.getWeather(48.1374, 11.5755);
        //            alert(
        //                "You have disabled location service. Allow 'This APP' to access your location. Your current location will be used for calculating Real time weather."
        //            );
        //        });
        //} else {
        //    alert("Geolocation not available");
        //}

        this.getWeather(48.1374, 11.5755);
        
        this.backggroundImages = this.importAllImages(require.context('./images/background', false, /\.(png|jpe?g|jpeg|svg)$/));
        this.setBackgroundImage(this.backggroundImages[0]);

        this.timerID = setInterval(
            () => this.getWeather(this.state.lat, this.state.lon),
            60000
        );

        this.readStations();

        this.backgroundTimer = setInterval(() => {
            this.setBackgroundImage(this.backggroundImages[Math.floor(Math.random() * this.backggroundImages.length)]);
        }, 50000);
    }


    readStations() {
        let noData = [];
        
        for (let i = 0; i < locations.length; i++) {
            setTimeout(function timer() {
                this.datatos = this.getPollen(locations[i].code);
                this.locationDescription = locations[i].description;
                if (i === (locations.length - 1)) {
                    this.readStations();
                }              

                this.datatos.then((state) => {
                    if (state.length === 0) {
                        if (!noData.includes(locations[i].description)) {
                            noData.push(locations[i].description);
                        }
                        this.setState({
                            noDataList: noData
                        })
                    }
                });

            }.bind(this), i * 10000);
        }
    }


    importAllImages(r) {
        return r.keys().map(r);
    }

    setBackgroundImage(path) {

        const element = document.getElementById('root');

        if (element) {
            element.style.background = `url(${process.env.PUBLIC_URL + path})`;
            element.style.backgroundRepeat = "no-repeat";
            element.style.backgroundPosition = "center";
            element.style.backgroundAttachment = "fixed";
            element.style.webkitBackgroundSize = "cover";
            element.style.backgroundSize = "cover";
            element.style.fontFamily = `"Open Sans", sans-serif`;
            element.style.textAlign = "center";
            element.style.position = "fixed";
            element.style.height = "100%";
            element.style.width = "100%";
        }
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
        clearInterval(this.pollenTimer);
        clearInterval(this.backgroundTimer);
    }

    // tick = () => {
    //   this.getPosition()
    //   .then((position) => {
    //     this.getWeather(position.coords.latitude, position.coords.longitude)
    //   })
    //   .catch((err) => {
    //     this.setState({ errorMessage: err.message });
    //   });
    // }

    getPosition = (options) => {
        return new Promise(function (resolve, reject) {
            navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
    };

    flattenObject(ob) {
        var toReturn = {};

        for (var i in ob) {
            if (!ob.hasOwnProperty(i)) continue;

            if ((typeof ob[i]) == 'object' && ob[i] !== null) {
                var flatObject = this.flattenObject(ob[i]);
                for (var x in flatObject) {
                    if (!flatObject.hasOwnProperty(x)) continue;
                    toReturn[x] = flatObject[x];
                }
            } else {
                toReturn[i] = ob[i];
            }
        }
        return toReturn;
    }

    flattenArray(ob) {
        var toReturn = [];
        for (var i in ob) {
            const flatten = this.flattenObject(ob[i]);
            //console.log(flatten);
            if (flatten['value'] > 0 && flatten['polle'] !== 'Varia')
                toReturn[i] = flatten;
        }
        return toReturn;
    };

    getPollen = async (location) => {
        //const api_call = await fetch(`https://epin.lgl.bayern.de/api/measurements?from=1709251200&to=1709337540&locations=DEMUNC`);
        //const api_call = await fetch(`https://epin.lgl.bayern.de/api/measurements?locations=DEMUNC`);
        const api_call = await fetch(`https://pollenscience.eu/api/measurements?locations=${location}`);
        const dataPollen = await api_call.json();
       // console.log(dataPollen);
        const pollenArray = this.flattenArray(dataPollen.measurements);
        //console.log(pollenArray);
        this.setState({
            pollenList: pollenArray
        })
        return pollenArray;
    };

    getWeather = async (lat, lon) => {
        const api_call = await fetch(
            `${apiKeys.base}weather?lang=de&lat=${lat}&lon=${lon}&units=metric&APPID=${apiKeys.key}`
        );
        const data = await api_call.json();
        this.setState({
            lat: lat,
            lon: lon,
            city: data.name,
            temperatureC: Math.round(data.main.temp),
            temperatureF: Math.round(data.main.temp * 1.8 + 32),
            humidity: data.main.humidity,
            main: data.weather[0].main,
            country: data.sys.country,
            description: data.weather[0].description
        });

        switch (this.state.main) {
            case "Haze":
                this.setState({ icon: "CLEAR_DAY" });
                break;
            case "Clouds":
                this.setState({ icon: "CLOUDY" });
                break;
            case "Rain":
                this.setState({ icon: "RAIN" });
                break;
            case "Snow":
                this.setState({ icon: "SNOW" });
                break;
            case "Dust":
                this.setState({ icon: "WIND" });
                break;
            case "Drizzle":
                this.setState({ icon: "SLEET" });
                break;
            case "Fog":
                this.setState({ icon: "FOG" });
                break;
            case "Smoke":
                this.setState({ icon: "FOG" });
                break;
            case "Tornado":
                this.setState({ icon: "WIND" });
                break;
            default:
                this.setState({ icon: "CLEAR_DAY" });
        }
    };

    render() {
        if (this.state.temperatureC) {
            return (
                <React.Fragment>
                    <div id="pollenBackground" className="city">
               
                        <div className="current-pollen">
                            <div className="title">
                                <h2>{/*this.state.city*/}Deutschland</h2>
                                <h3>{this.state.country}</h3>
                            </div>

                            <h2>Aktueller Pollenflug </h2><h3> {this.locationDescription}:</h3>

                            {
                                this.state.pollenList.map((pollen) =>
                                    <p>
                                        {this.state.pollenList.length > 0 ? (
                                            <ul>
                                                <li key={Date.now()}>{translateWord(pollen.polle, this.state.germanTranslationDict)}</li>
                                                <li> {Math.round(pollen.value)} {checkIfPollenExists(translateWord(pollen.polle, this.state.germanTranslationDict)) ? (
                                                    <span> Sporen </span>
                                                ) : (
                                                    <span>Pollen/m<sup>3</sup></span>
                                                )}</li>
                                            </ul>
                                        ) : null}
                                    </p>)
                            }
                            {this.state.pollenList.length === 0 ? (
                                <p>Keine aktuellen Daten...</p>
                            ) : null}

                        </div>
                        <div className="mb-icon">
                            {" "}
                            <ReactAnimatedWeather
                                icon={this.state.icon}
                                color={defaults.color}
                                size={defaults.size}
                                animate={defaults.animate}
                            />
                            <p>{this.state.description}</p>
                        </div>

                        <div className="nodata">

                            {this.state.noDataList.length > 100 ? (
                                <ul>
                                    <li><img alt="nodata" src={nodataImg} style={{ width: "8%", WebkitUserDrag: "none" }} />{this.state.noDataList.join(', ')}</li>
                                </ul>
                            ) : null}

                            
                        </div>
                        <div className="date-time">  

                            <div className="dmy">
                                <div id="txt"></div>
                                <div className="current-time">
                                    <Clock format="HH:mm:ss" interval={1000} ticking={true} />
                                </div>
                                <div className="current-date">{dateBuilder(new Date())}</div>
                            </div>
                            <div className="temperature">
                                <p>
                                    {this.state.temperatureC}°<span>C</span>
                                </p>
                                {/* <span className="slash">/</span>
                {this.state.temperatureF} &deg;F */}

                            </div>                           
                        </div>

                    </div>

                    

                    <Forcast icon={this.state.icon} weather={this.state.description} />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <img alt="pollen" src={loader} style={{ width: "50%", WebkitUserDrag: "none" }} />
                    <h3 style={{ color: "white", fontSize: "22px", fontWeight: "600" }}>
                        Detecting your location
                    </h3>
                    <h3 style={{ color: "white", marginTop: "10px" }}>
                        
                    </h3>
                </React.Fragment>
            );
        }
    }
}

export default Pollen;

